import SingleTabComponent from '../components/SingleTab/viewer/SingleTab';
import SingleTabController from '../.components/SingleTab/viewer/SingleTab.controller';


const SingleTab = {
  component: SingleTabComponent,
  controller: SingleTabController
};


export const components = {
  ['SingleTab']: SingleTab
};

